// Same colours defined in scss for the scss files (sorry 🙆‍♂️)

export default {
  black0: '#000000',
  black0a: 'rgba(0, 0, 0, 0.2)',
  black0b: 'rgba(0, 0, 0, 0.75)',
  white0: '#ffffff',
  white0a: 'rgba(255, 255, 255, 0.5)',
  white1: '#fcfcfc',
  white2: '#f6f6f6',
  white3: '#f0f0f0',
  grey1: '#eee',
  grey2: '#ddd',
  grey3: '#ccc',
  grey4: '#bbb',
  grey5: '#aaa',
  grey6: '#999',
  grey7: '#777',
  grey8: '#666',
  grey9: '#555',
  pink0: '#fbeff9',
  pink1: '#e33f96',
  blue0: 'aliceblue',
  yellow0: 'lightYellow',
  yellow1: '#d0d092',
}
