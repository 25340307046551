import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Context as SectionContext } from '../context/sectionContext'
import styles from './header.module.scss'
import { setColourOpacity } from './utils'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

function transitionBackground(d) {
  gsap.to('header', { backgroundColor: setColourOpacity(d.colour, 0.5) })
}

// Note, there are differences between a home- and a
// non-homepage Header controlled by the `home` prop (1)

// Each header item.
const Item = ({ name, active, onClick, home }) => {
  function click(e, elementId) {
    onClick(name)
    if (home) {
      e.preventDefault()
      gsap.to(window, { scrollTo: `#${elementId}` })
    }
  }

  return (
    <Link to={`/#${name}`} onClick={e => click(e, name)}>
      <li aria-hidden="true">
        <svg width="50" height="40">
          <text
            className={active ? styles.bold : null}
            x="50%"
            y="50%"
            dy="0.35em"
          >
            {name}
          </text>
          <line
            className={active ? styles.straight : null}
            y1="98%"
            x2="100%"
            y2="98%"
          />
        </svg>
      </li>
    </Link>
  )
}

// The main Header component.
const Header = ({ home }) => {
  // Get section context to build header.
  const { sections, selectedSection, setSelectedSection } = useContext(
    SectionContext
  )

  // Set active header on scroll. (2)
  useEffect(() => {
    if (home) {
      sections.forEach((d, i) => {
        // Set section names and set header colour on scroll down.
        ScrollTrigger.create({
          trigger: `#${d.name}`,
          start: `top top${i ? '+=1px' : '-=1px'}`,
          end: 'bottom bottom-=1px',
          onEnter: () => {
            transitionBackground(d)
            setSelectedSection(d.name)
          },
          onEnterBack: () => {
            setSelectedSection(d.name)
            transitionBackground(d)
          },
        })

        // Set header colour on scroll up.
        ScrollTrigger.create({
          trigger: `#${d.name}`,
          end: `bottom top`,
          onEnterBack: () => {
            transitionBackground(d)
          },
        })
      })
      return () => {
        ScrollTrigger.getAll().forEach(t => t.kill())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home, sections, setSelectedSection])

  return (
    <header className={`${styles.header} ${!home && styles.unfix}`}>
      <nav>
        <ul className={styles.navList}>
          {sections.map((d, i) => (
            <Item
              key={i}
              name={d.name}
              active={d.name === selectedSection}
              onClick={setSelectedSection}
              home={home}
            />
          ))}
        </ul>
      </nav>
    </header>
  )
}

Item.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  home: PropTypes.bool,
}

Header.propTypes = {
  home: PropTypes.bool,
}

export default Header

// (1) The header component does slightly different things on the home page
// as opposed to being on a project/blog page:
// - home: is position fixed | other: is position relative
// - home: changes active section on sroll | other: doesn't do that
// - home: scrolls to section | other: linking back to home

// (2) This just needs to be set once on some top level and lived initially
// on the index page. However, it needs access to the SectionContext
// which is only available within the SectionProvider. So this moved here.
