import { color, hsl } from 'd3-color'

function setColourOpacity(col, opacity = 1) {
  return color(col).copy({ opacity }).formatRgb()
}

function setColour(col, hue = 0, dark = 0) {
  const c = hsl(col)
  c.h += hue
  return c.darker(dark).formatRgb()
}

export { setColourOpacity, setColour }
