import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const Context = createContext({})

const Provider = props => {
  // Get the initial state from the props.
  const {
    sections: initialSections,
    selectedSection: initialSelectedSection,
    children,
  } = props

  // Set the state.
  const [sections, setSections] = useState(...[initialSections])
  const [selectedSection, setSelectedSection] = useState(initialSelectedSection)

  // Make the context object.
  const sectionContext = {
    sections,
    selectedSection,
    setSections, // (1)
    setSelectedSection,
  }

  return <Context.Provider value={sectionContext}>{children}</Context.Provider>
}

Provider.propTypes = {
  sections: PropTypes.array,
  selectedSection: PropTypes.string,
  children: PropTypes.node,
}

export { Context, Provider }

// (1) Not used so far, but exported to not trigger barkings.
