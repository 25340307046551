import colours from '../../src/styles/colours'

export default [
  {
    name: 'start',
    colour: colours.white1,
    footer: `${new Date().getFullYear()} datamake | lars verspohl`,
    url: null,
  },
  {
    name: 'made',
    colour: colours.pink0,
    footer: 'projects and experiments',
    url: 'project',
  },
  {
    name: 'written',
    colour: colours.blue0,
    footer: 'posts, books and courses',
    url: 'blog',
  },
  {
    name: 'about',
    colour: colours.yellow0,
    footer: `△`,
    url: null,
  },
]
