import React from 'react'
import PropTypes from 'prop-types'
import styles from './layout.module.scss'

const Layout = ({ children }) => (
  <div className={styles.container}>{children}</div>
)

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
